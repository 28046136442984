<template>
  <div class="app-container">
    <nav>
      <div class="tips">
        <el-button @click="toPath" class="btn">返回 </el-button>
        <el-button @click="toCleanup" type="primary" class="btn" :disabled='disabled'> 清空回收站</el-button>
      </div>
      <div class="text">
        <!-- <i class="iconfont iconicon1-12"></i> 回收站的账套会在用户删除 <b>90</b> 日后自动清除哦~ -->
      </div>
      <div class="operation">
        <el-input placeholder="搜索..." v-model="queryValue" class="inputSearch" @keyup.enter.native="searchList">
          <i class="iconfont iconicon1-30" slot="suffix" @click="searchList"></i>
        </el-input>
      </div>
    </nav>

    <div class="filter-container">
      <recycle-table :value='queryValue' @recycleTotal='changeStatus' ref='recycleTable' />
    </div>

  </div>
</template>

<script>
  import RecycleTable from './modules/recycleTable'

  export default {
    components: { RecycleTable },
    data() {
      return {
        queryValue: '',
        cleanupVis: false,
        disabled : true,
      }
    },
    methods: {
      searchList() {
        this.$refs.recycleTable.getList();
      },
      toPath() {
        this.$router.push('/install/company')
      },
      changeStatus(val){  // 根据emit返回当前列表是否有数据
        val > 0 ? this.disabled = false : this.disabled = true;
      },
      toCleanup() {
        this.$refs.recycleTable.showCleanup();
      },
    }
  }
</script>

<style lang='less' scoped>
  nav {
    height: 60px;
    display: flex;
    padding: 0 18px;
    // line-height: 60px;
    align-items: center;
    background-color: #fff;
    justify-content: space-between;
    .tips {
      span {
        margin-left: 18px;
      }
      .btn {
        margin-right: 18px;
      }
    }
    .operation {
      display: flex;
      .inputSearch {
        margin-right: 10px;
        /deep/ .el-input__inner {
          border-radius: 21px !important;
          height: 35px !important;
          // line-height: 35px;
        }
        /deep/ .el-input__suffix {
          right: 16px;
          top: 10px;
          color: #4f71ff;
        }
      }
      i {
        padding: 10px 8px;
        cursor: pointer;
      }
    }
  }
  .text {
    color: #ff9700;
    font-size: 15px;
    i {
      margin-right: 7px;
    }
  }
  .filter-container {
    height: calc(100% - 5.21rem);
  }
</style>
