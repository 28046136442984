<template>
  <div style="height: 93%;">
      <comp-table :tableData="tableData" :tableHeader="tableHeader" v-loading="isLoading" :total="total" :page="page" :pageSize="pageSize"
        @current-events="getList" @save-current="savePage" v-if="initStatus" @size-events="changeSize" />
      <nodata :message="'暂无相关账套'" h="400" v-else />
      <resume-dialog :visible='resumeVis' :cancelDialog='cancelResume' :confirmDialog='confirmResume' :rowData='rowData' :isdeling='isdeling'/>
      <remove-dialog :visible='deleteVis' :cancelDialog='cancelDelete' :confirmDialog='confirmDelete' :rowData='rowData' :isdeling='isdeling'/>
      <cleanup-dialog :visible='cleanupVis' :cancelDialog='cancelCleanup' :confirmDialog='confirmCleanup' :isdeling='isdeling'/>
  </div>
</template>

<script>
  import removeDialog from './dialog/remove'
  import resumeDialog from './dialog/resume'
  import cleanupDialog from './dialog/cleanup'

  export default {
    props:['value'],
    name: "complexTable",
    components:{
        removeDialog,
        resumeDialog,
        cleanupDialog
    },
    data() {
      return {
        tableData: [], // 请求到的表格数据
        tableHeader: [
          // 表头信息
          {
            prop: "name",
            label: "单位名称",
            // router: true,
            fixed: true,
          },
          {
            currentPeriod: true,
            label: "当前记账年月",
          },
          {
            date: true,
            label: "账套启用年月",
          },
          {
            prop: "accountingStandard",
            accounting: true,
            label: "会计准则",
          },
          {
            prop: "updateUserName",
            label: "操作人",
          },
          {
            prop: "updateTime",
            label: "删除日期",
          },
          {
            prop: "operation",
            label: "操作",
            center: 'center',
            minWidth: 32,
            operation:
               [
                { name: "恢复", clickFun: this.showResume },
                { name: "删除", clickFun: this.showDelete }, // makeSure: true,
              ]
          },
        ],
        multipleSelection: [],

        //分页查询
        page: 1,
        data: [],
        total: 0,
        pageSize: 20,
        isLoading : false,
        initStatus: true,

        //提示窗
        resumeVis : false,
        deleteVis : false,
        cleanupVis: false,

        isdeling: false,
        rowData:{},
      };
    },
    methods: {
      // 所有账套列表
      savePage(val) {
        this.page = val
      },
      changeSize(size) {
        this.pageSize = size
        this.getList()
      },
      getList(val) {
        this.isLoading = true
        this.$store.dispatch("FindCompanyList", {
          inRecycle:'1',  //回收站
          name: this.value,
          userId: this.getToken('userId'),
          index: val ? val : this.page,
          rows: this.pageSize,
        }).then(res => {
          this.isLoading = false
          if (res.success) {
              this.initStatus = true;
              this.tableData = res.data.list;
              this.total = res.data.count;
              this.$emit('recycleTotal',res.data.count)
          } else {
              this.initStatus = false
              einvAlert.error("提示",res.msg)
          }
        }).catch(err => {
          this.isLoading = false
        })
      },
      sortEvents(type) {
        console.log(type);
      },
      more(){
        this.$router.push('/access/apply/server')
      },
      showCleanup(){
        this.cleanupVis = true;
      },
      cancelCleanup(){
        this.cleanupVis = false;
      },
      confirmCleanup(){
        this.cleanupDomain()
      },
      showResume(item){
        this.rowData = item ;
        this.resumeVis = true;
      },
      cancelResume(){
        this.resumeVis = false;
      },
      confirmResume(){
        this.resumeDomain(this.rowData)
      },
      showDelete(item){
        this.rowData = item ;
        this.deleteVis = true;
      },
      cancelDelete(){
        this.deleteVis = false;
      },
      confirmDelete(){
        this.deleteDomain(this.rowData)
      },
      // 恢复
      resumeDomain(item) {
        this.isdeling = true;
        this.$store.dispatch("ResumeAccount",{userId: this.getToken('userId'),asId:item.id},).then(res => {
            if (res.success) {
                einvAlert.success("提示",'恢复成功');
                this.isdeling = false;
                this.resumeVis = false;
                this.getList();
            } else {
                this.isdeling = false;
                this.resumeVis = false;
                einvAlert.error("提示",res.msg);
            }
        }).catch(err => {
        })
      },
      // 删除
      deleteDomain(item) {
        this.isdeling = true;
        this.$store.dispatch("DropAccount",{userId: this.getToken('userId'),asId:item.id},).then(res => {
          if (res.success) {
            einvAlert.success("提示",'删除成功');
            this.isdeling = false;
            this.deleteVis = false;
            let maxPage = Math.ceil((this.total - 1) / this.pageSize)
              if (this.page > maxPage) {
                this.page = maxPage
              }
            this.getList();
          } else {
            einvAlert.error("提示",res.msg);
            this.isdeling = false;
            this.deleteVis = false;
          }
        }).catch(err => {
        })
      },
        // 清空回收站
      cleanupDomain() {
        this.isdeling = true;
        this.$store.dispatch("CleanupAccount",{userId: this.getToken('userId')}).then(res => {
            if (res.success) {
              einvAlert.success("提示",'清空成功');
              this.isdeling = false;
              this.cleanupVis = false;
              this.getList(1);
            } else {
                einvAlert.error("提示",res.msg);
                this.isdeling = false;
                this.cleanupVis = false;
            }
        }).catch(err => {
        })
      },
      editDomain(row) {
        this.$router.push('/install/company/edit?id='+row.id)
      },
    },
    mounted() {
      this.getList(); //实例列表
    },
  };
</script>

<style scoped>
  .filter-container {
    background: #fff;
    margin-top: 20px;
    padding: 20px 20px 0px 20px;
  }
  .el-form-item {
    margin-bottom: 10px;
  }
  .filter-item {
    margin-left: 12px;
    width: 130px;
    margin-bottom: 10px;
  }
  .btn-item {
    height: 32px;
    margin-left: 12px;
    vertical-align: super;
  }
</style>
